
/**
 *
 *
 * @param {HTMLElement} item
 */
function scrollToHandler(item) {

    /** @type {HTMLElement} */ const target = document.querySelector(item.getAttribute('href'));

    item.addEventListener('click', (e) => {
        e.preventDefault();

        if (target) {
            target.scrollIntoView({
                behavior: 'smooth'
            });

            // document.location.hash = item.getAttribute('href');
        }
    });

}

const links = Array.from(document.querySelectorAll('.jsScrollLink'));

for (const link of links) {
    scrollToHandler(link);
}
